import React from 'react'

import PageWrapper from 'components/layout/page-wrapper'
import DescriptionSection from 'components/pages/promo/DescriptionSection'
import HeaderSection from 'components/pages/promo/HeaderSection'

function GrowthInitiativePage({ images, pageContext }) {
  // get page data.
  const { colorsOverride, headerSection, howtoSection, descriptionSection } = pageContext.data

  const headerSectionImages = {
    exodusImage: images.headerLogoExodus.childImageSharp.gatsbyImageData,
    promoImage: images.headerLogoPromo.childImageSharp.gatsbyImageData,
  }

  return (
    <PageWrapper className="x__promo-page" backgroundColor="rgb(13, 6, 28)">
      {headerSection && (
        <HeaderSection
          colors={colorsOverride}
          data={{ ...headerSection, howto: { ...howtoSection } }}
          images={headerSectionImages}
        />
      )}
      {descriptionSection && (
        <DescriptionSection data={{ ...descriptionSection, howto: { ...howtoSection } }} />
      )}
    </PageWrapper>
  )
}

export default GrowthInitiativePage
