import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'

import SimpleHead from 'components/head/SimpleHead'
import GrowthInitiativePage from 'templates/growth-initiative'

function PufferPromoPage() {
  // get images.
  const images = useStaticQuery(graphql`
    query {
      headerLogoExodus: file(relativePath: { regex: "/^growth-initiative\/img\/promo_exodus\\.png$/" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, width: 705, placeholder: BLURRED)
        }
      }
      headerLogoPromo: file(relativePath: { regex: "/^growth-initiative\/img\/promo_brand\\.png$/" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, width: 705, placeholder: BLURRED)
        }
      }
    }
  `)

  return <GrowthInitiativePage images={images} pageContext={pageContext} />
}

export default PufferPromoPage

// head and meta data.
export const Head = () => {
  const { metaTags, path } = pageContext.data
  return (
    <SimpleHead
      title={metaTags.title}
      description={metaTags.description}
      keywords={metaTags.keywords}
      path={path}
    />
  )
}

const pageContext = {
  data: {
    path: '/puffer',
    metaTags: {
      title: 'Exodus and Puffer',
      description:
        'Earn 1.5x Puffer points when you stake and hold PufETH via the dapp browser in the Exodus mobile app',
      keywords: 'Exodus, Puffer, stake and Hold, PufETH, dapp, dapp browser, mobile',
    },

    headerSection: {
      h1: 'Exodus <br />and Puffer',
      h2: 'Earn 1.5x Puffer points when you stake and hold PufETH via the dapp browser in the Exodus mobile app.',
    },

    howtoSection: {
      h3: 'How to',
      steps: [
        {
          title: 'Step One',
          description:
            'Open your Exodus mobile app and tap the Profile button in the bottom right corner.',
        },
        {
          title: 'Step Two',
          description:
            'Inside your Profile, tap “Web3” to open the Web3 browser. Click on the "Puffer Finance" banner.',
        },
        {
          title: 'Step Three',
          description: 'Stake PufETH and hold it to start earning 1.5x points.',
        },
      ],
      cta: {
        copy: 'Get Started',
        path: 'https://quest.puffer.fi/quest?utm_source=exoduswallet',
      },
    },
  },
}
