import { useEffect, useState } from 'react'
import useDetectUserAgent from './use-detect-user-agent'
import useDimensions from './useDimensions'

/**
 * Combines the user agent and screen width to detect if the user is on a mobile device.
 * The main purpose of this hook is to have a single way to detect if the user is on a mobile device
 * and avoid using `isMobile` from `useDetectUserAgent` and `useDimensions` separately.
 */
const useIsMobileDevice = (breakpoint = 768) => {
  const { isMobile: isMobileUserAgent, loading } = useDetectUserAgent()
  const { breakpoint: isMobileBreakpoint } = useDimensions(breakpoint)
  const [isMobile, setIsMobileDevice] = useState(false)

  useEffect(() => {
    setIsMobileDevice(isMobileUserAgent || isMobileBreakpoint)
  }, [isMobileUserAgent, isMobileBreakpoint])

  return { isMobile, loading }
}

export default useIsMobileDevice
